<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <h4>Progress</h4>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in data"
          :key="item.icon"
          xl="2"
          sm="6"
          class="mb-5"
        >
          <b-media no-body>
            <b-media-aside
              id="circle"
              class="mr-2"
              v-if="item.location==='Start'||item.location==='End'"
            >
              <b-avatar
                size="80"
                :variant="item.activities.length!==0 ? 'light-primary' : 'light-danger'"
              >
                <span>{{ item.location }}</span>
              </b-avatar>
            </b-media-aside>
            <b-media-aside
              id="circle"
              class="mr-2"
              v-else
              v-on="item.activities.length > 0 ? { click: () => showModal(item.activities) } : {}"
              :class="item.activities.length > 0 ? 'cursor-pointer' : ''"
            >
              <b-avatar
                size="80"
                :variant="item.activities.length!==0 ? 'light-primary' : 'light-danger'"
              >
                <span>{{ item.location }}</span>
              </b-avatar>
            </b-media-aside>
            <b-media-body v-if="item.location!=='End'" class="my-auto">
              <feather-icon
                size="24"
                icon="ArrowRightIcon"
              />
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
    <b-modal id="bv-modal-example" size="xl" hide-footer>
      <b-card 
        v-for="item in modalData"
        :key="item.location"
      >
        <b-card-body>
          <h4 class="mb-2">Location : {{item.location_name}}</h4>
          <h4 class="mb-2">Type : {{item.type}}</h4>
          <h4 class="mb-2">Date : {{toDate(item.tstamp)}}</h4>
        </b-card-body>
        <b-card-body v-if="item.type=='report'">
          <h4 class="mb-5">{{item.data.notes}}</h4>
          <swiper
            class="swiper-navigations"
            :options="swiperOptions"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          >
            <swiper-slide
              v-for="data in item.data.foto"
              :key="data"
            >
              <b-img
                :src="data"
                fluid
                center
                height="400"
                width="400"
              />
            </swiper-slide>

            <!-- Add Arrows -->
            <div
              slot="button-next"
              class="swiper-button-next"
            />
            <div
              slot="button-prev"
              class="swiper-button-prev"
            />
          </swiper>
        </b-card-body>
      </b-card>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BModal,
  BImg,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BModal,
    Swiper,
    SwiperSlide,
    BImg,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    showModal(data){
      this.modalData = data
      this.$bvModal.show('bv-modal-example')
    },
    toDate(time) {
      let myDate = null
      if (time === 0) {
        myDate = new Date()
      } else {
        myDate = new Date(time * 1000)
      }
      const year = myDate.getFullYear()
      const month =
        myDate.getMonth() + 1 < 10
          ? '0' + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1
      const date =
        myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      const hour =
        myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
      const minute =
        myDate.getMinutes() < 10
          ? '0' + myDate.getMinutes()
          : myDate.getMinutes()
      const second =
        myDate.getSeconds() < 10
          ? '0' + myDate.getSeconds()
          : myDate.getSeconds()
      return (
        year +
        '-' +
        month +
        '-' +
        date +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
  },
  data() {
    return {
      modalData:[],
      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/banner-7.jpg') },
        { img: require('@/assets/images/banner/banner-4.jpg') },
        { img: require('@/assets/images/banner/banner-14.jpg') },
        { img: require('@/assets/images/banner/banner-3.jpg') },
        { img: require('@/assets/images/banner/banner-2.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
}
</script>

<style scoped>
  .cursor-pointer{
    cursor:pointer;
  }
</style>
